import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../../assets/images/work_space/SideHome.svg";
import { ReactComponent as AdminIcon } from "../../assets/images/work_space/AdminIcon.svg";
import { ReactComponent as AdminIcon2 } from "../../assets/images/work_space/info.svg";
import { ReactComponent as Edit } from "../../assets/images/work_space/Edit.svg";
import { ReactComponent as Info } from "../../assets/images/work_space/user_info.svg";
import { ReactComponent as SideSettings } from "../../assets/images/work_space/SideSettings.svg";
import { User } from "../../constants/userConstants";
import { Link } from "react-router-dom";
import { APPLICATIONS_URL } from "../../constants/routes";
import { FormDialogComponent } from "../../components/editorComponents/form/FormDialogComponent";
import { HeaderIcons } from "../../icons/HeaderIcons";
import { Colors } from "../../constants/Colors";
import AppInviteUsersForm from "../organization/AppInviteUsersForm";
import { useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { Tooltip } from "@blueprintjs/core";

const SideNavigationWrapper = styled.div`
  height: calc(100% - 32px);
  position: fixed;
  top: 32px;
  left: 0px;
  z-index: 11;
  width: 48px;
  background: #ececec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #d9d9d9;
  padding-bottom: 35px;
  svg {
    cursor: pointer;
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapper_icon_nav {
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 23px;
        height: 23px;
        fill: #6c767e;
        path {
          fill: #6c767e;
        }
      }
    }
    .active {
      border-left: 2px solid #3b82ff;
      svg {
        fill: #3b82ff;
        path {
          fill: #3b82ff;
        }
      }
    }
    .active:hover {
      svg {
        width: 27px;
        height: 27px;
        fill: #6c767e;
        path {
          fill: #6c767e;
        }
      }
    }
  }
`;
type PageHeaderProps = {
  openLeftMenu?: () => void;
  openUserPanel?: () => void;
  active: string;
  openAnalitic?: () => void;
  openAdmiPanel?: () => void;
};
export const SideNavigation = (props: PageHeaderProps) => {
  const currentApplication = useSelector((state: AppState) => {
    return state.ui.applications?.currentApplication;
  });
  const currentUser = useSelector((state: AppState) => {
    return state.ui.users.currentUser;
  });
  // console.log(currentUser, "currentUsercurrentUser");
  return (
    <SideNavigationWrapper>
      {window.location.pathname == "/workspace" ? (
        <>
          {" "}
          <div className="nav">
            <div
              className={`wrapper_icon_nav ${props.active == "HOME" &&
                "active"}`}
            >
              <Link to={APPLICATIONS_URL}>
                <HomeIcon></HomeIcon>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="nav">
            <div
              className={`wrapper_icon_nav ${props.active == "EDIT" &&
                "active"}`}
            >
              <Tooltip content={"Home"} usePortal={false}>
                <Link to={APPLICATIONS_URL}>
                  <HomeIcon></HomeIcon>
                </Link>
              </Tooltip>
            </div>
            <div
              className={`wrapper_icon_nav ${props.active == "EDIT" &&
                "active"}`}
              onClick={props.openLeftMenu}
            >
              <Tooltip content={"Services"} usePortal={false}>
                <Edit></Edit>
              </Tooltip>
            </div>
            {/* <div
              className={`wrapper_icon_nav ${props.active == "EDIT" &&
                "active"}`}
              onClick={props.openAnalitic}
            >
              <Tooltip content={"services"}
                usePortal={false}>
                <Info></Info>
              </Tooltip>

            </div> */}
            <div
              className={`wrapper_icon_nav ${props.active == "EDIT" &&
                "active"}`}
              onClick={props.openUserPanel}
            >
              <Tooltip content={"DeploymentManager"} usePortal={false}>
                <AdminIcon></AdminIcon>
              </Tooltip>
            </div>
            <div
              className={`wrapper_icon_nav ${props.active == "EDIT" &&
                "active"}`}
              onClick={props.openAdmiPanel}
            >
              <Tooltip content={"AdminPanel"} usePortal={false}>
                <AdminIcon2></AdminIcon2>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </SideNavigationWrapper>
  );
};

export default SideNavigation;
