import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ModalNodeRed } from "./PluginGroup/ModalNodeRed";
import { NodeRedModalEnv } from "./PluginGroup/NodeRedMadalEnv";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { IconName, IconNames } from "@blueprintjs/icons";
import { Button, Collapse, Icon, Overlay, Tooltip } from "@blueprintjs/core";
import { Help, ListItem, Name, Version } from "./JSDependencies";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import {
  checkNodeRed,
  deleteNodeRedPost,
  getList,
  getSettingsNodeRed,
  restartNodeRedApi,
  verifyNodeRed,
} from "../../../components/designSystems/appsmith/help/FileManagerApi";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { deleteDatasource } from "../../../actions/datasourceActions";
import { Colors } from "../../../constants/Colors";
import { ReactComponent as CopyIcon } from "../../../assets/icons/control/copy.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/control/delete.svg";
import { ReactComponent as Update } from "../../../assets/icons/help/update.svg";
import { AppToaster } from "../../../components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { ReactComponent as Add } from "../../../assets/images/work_space/add.svg";
import { ReactComponent as Close } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as Open } from "../../../assets/images/work_space/open.svg";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";
import { createIdLoading, LoadingItem } from "../DoclLayoutSettings";

export const Info = styled.div`
  // width: 60px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  span {
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    width: 14px;
    height: 14px;
    fill: #6c767e;
    margin-right: 2px;
    margin-left: 2px;
    path {
      fill: #6c767e;
    }
  }
`;
export const WrapperItemCollapse = styled.div`
  .bp3-collapse-body {
    border-left: 1px solid #afb4b8;
    margin-left: 8px;
  }
  // .bp3-collapse-body:hover{
  //   background: #e8e8e8;
  // }
  // .bp3-collapse {
  //   background: rgba(255, 255, 255, 0.7);
  // }
  .active {
    background: #e8e8e8;
  }
`;
export const WrapperCollapse = styled.div`
  width: 100%;
  height: 22px;
  cursor: pointer;
  display: flex;
  padding: 3px 2px 2px 3px;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-family: "Roboto";
  color: #495870;
  &:hover {
    background: #e8e8e8;
  }
  .icon_open_close {
    width: 10px;
  }
  .bp3-collapse-body {
    border-left: 1px solid #6c767e;
    margin-left: 8px;
  }
`;
export const AddNodeRed = styled.div`
  width: 16px;
  margin-right: 6px;
  cursor: pointer;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfdfdf;
  &:hover {
    background: #f3f3f3;
    color: #dfdfdf;
  }
`;
export const NodeRedCollapse = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const openModal = () => {
    childRefCreate?.current?.openModal();
  };

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await verifyNodeRed(keycloakAccessToken || "");
  //     } catch (e) {
  //       setIsErrorModal(true);
  //     }
  //   })();
  // }, []);

  // useEffect(() => {
  //   setInterval(async () => {
  //     try {
  //       const res = await verifyNodeRed(keycloakAccessToken || "");
  //     } catch (e) {
  //       setIsErrorModal(true);
  //     }
  //   }, 30000);
  // }, []);

  const handleLogout = useCallback(() => {
    window.location.href =
      window.location.origin + "/oauth2/authorization/appcreator?action=logout";
  }, []);

  const dispatch = useDispatch();
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const datasourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );
  const filterDataSource = datasourcesList.filter((item: any) => {
    if (
      item.id &&
      item.settings_data &&
      item.settings_data.applicationId === currentApplication?.id
    ) {
      return true;
    }
  });

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const icon = isOpen ? <Open></Open> : <Close></Close>;

  const openNodeRed = useCallback((item: any) => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: item.projectName,
        component: "nodeRed",
        props: item.urlNodeRed,
      },
    });
  }, []);

  const deleteNodeRed = useCallback(
    async (item: any) => {
      try {
        const deleteItem = await deleteNodeRedPost(
          {
            service_label: item.settings_data.serviceId,
            orgId: currentOrg,
          },
          keycloakAccessToken || "",
        );
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        const deleteId = JSON.stringify({
          id: item.name,
          type: "nodeRed",
          props: item.datasourceConfiguration.url,
        });
        dispatch({
          type: ReduxActionTypes.REMOVE_LAYOUT_ITEM,
          payload: {
            id: deleteId,
            title: item.name,
            component: "nodeRed",
            props: item.datasourceConfiguration.url,
          },
        });
      } catch (e) {}
    },
    [datasourcesList, keycloakAccessToken],
  );
  const restartNodeRed = useCallback(
    async (item: any) => {
      try {
        const { data } = await restartNodeRedApi(
          {
            serviceId: item.settings_data.serviceId,
            orgId: currentOrg,
          },
          keycloakAccessToken || "",
        );
        if (data.data.error) {
          AppToaster.show({
            message: `${data.data.message}`,
            type: ToastType.ERROR,
          });
        }
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        dispatch({
          type: ReduxActionTypes.UPDATE_LAYOUT_ITEM,
          payload: {
            id: JSON.stringify({
              id: item.name,
              type: "nodeRed",
              props: item.datasourceConfiguration.url,
            }),
            content: (
              <LoadingItem
                url={item.datasourceConfiguration.url}
                type={"Loading"}
                title={item.name}
                id={createIdLoading({
                  id: item.name,
                  type: "Loading",
                  props: item.datasourceConfiguration.url,
                  subtype: "nodered",
                })}
              />
            ),
            title: item.name,
            group: "allowWindow",
            closable: true,
          },
        });
      } catch (e) {}
    },
    [datasourcesList, keycloakAccessToken],
  );
  const UpdateNodeRed = useCallback(
    async (item: any) => {
      try {
        const { data } = await checkNodeRed(
          {
            service_label: item.settings_data.serviceId,
            orgId: currentOrg,
          },
          keycloakAccessToken || "",
        );
        if (data.data.error) {
          AppToaster.show({
            message: `${data.data.message}`,
            type: ToastType.ERROR,
          });
        }
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      } catch (e) {}
    },
    [datasourcesList, keycloakAccessToken],
  );
  const childRef = useRef<any>({});
  const childRefCreate = useRef<{ openModal: any; setValue: any }>();
  const refModalsDelete = useRef<any>({});

  const handleCollapse = useCallback(async () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    setIsOpen(prevState => !prevState);
  }, [currentApplication?.id, keycloakAccessToken]);

  const openModalPrev = useCallback((item: any) => {
    childRef?.current[item].openModal();
  }, []);

  const openModalDelete = useCallback(index => {
    if (refModalsDelete) {
      refModalsDelete.current[index].openModal();
    }
  }, []);

  return (
    <WrapperItemCollapse>
      <WrapperCollapse className={`${isOpen && "active"}`}>
        <div
          onClick={handleCollapse}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="icon_open_close">{icon}</div>
          <span style={{ paddingLeft: "4px" }}>Flow Builder</span>
        </div>
        <AddNodeRed onClick={openModal}>
          <Add></Add>
        </AddNodeRed>
      </WrapperCollapse>
      <Collapse isOpen={isOpen}>
        {filterDataSource?.map((item: any, index: number) => {
          return (
            <ListItem key={item.name}>
              <Name
                className={item.settings_data.status}
                onClick={() => openNodeRed(item.settings_data)}
              >
                <span className="dots">{item.name}</span>
              </Name>
              <Info className="info_collapse">
                <Update onClick={() => restartNodeRed(item)}></Update>
                {item.settings_data.status == "inprocess" && (
                  <Update onClick={() => UpdateNodeRed(item)}></Update>
                )}

                <DeleteIcon onClick={() => openModalDelete(index)}></DeleteIcon>
                <ModalDelete
                  ref={element => (refModalsDelete.current[index] = element)}
                  name={`Node-RED  ${item.name}`}
                  item={item}
                  remove={deleteNodeRed}
                />
                <Help
                  icon="help"
                  iconSize={12}
                  color={Colors.DOVE_GRAY}
                  onClick={() => openModalPrev(index)}
                />
                <NodeRedModalEnv
                  item={item}
                  ref={element => (childRef.current[index] = element)}
                />
                <span
                  onClick={() => {
                    navigator &&
                      navigator.clipboard.writeText(
                        item.settings_data.urlNodeRed,
                      );
                    AppToaster.show({ type: "success", message: "Copied Url" });
                  }}
                >
                  <CopyIcon></CopyIcon>
                </span>
              </Info>
            </ListItem>
          );
        })}
      </Collapse>
      <ModalNodeRed ref={childRefCreate} />
      <Overlay isOpen={isErrorModal}>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div style={{ padding: "40px", background: "white" }}>
            <div style={{ paddingBottom: "10px", textAlign: "center" }}>
              Error NodeRed
            </div>
            <div style={{ color: "red" }}>
              NodeRed is an unauthorized need to log out and log in
            </div>
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              intent="success"
              onClick={handleLogout}
              text="log out"
            />
          </div>
        </div>
      </Overlay>
    </WrapperItemCollapse>
  );
});
NodeRedCollapse.displayName = "NodeRedCollapse";
