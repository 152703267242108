import Api from "./Api";

class TemplateUiApi extends Api {
  static url = "v1/ui_template";
  static fetchTemplate() {
    return Api.get(TemplateUiApi.url + "/list");
  }

  static createTemplate(name: string, value: any) {
    return Api.post(TemplateUiApi.url + `/create`, { name, value });
  }
  static deleteTemplate(id: string) {
    return Api.delete(TemplateUiApi.url + `/${id}`);
  }
}

export default TemplateUiApi;
