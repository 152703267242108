import {
  useAsyncDebounce,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import EditableText, {
  EditInteractionKind,
} from "../../../components/ads/EditableText";
import styled from "styled-components";
import UploadModal from "../../../components/designSystems/appsmith/help/UploadModal";
import { ReactComponent as ArrowUp } from "../../../assets/images/work_space/rowup.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/work_space/arrowdown.svg";
import { getList } from "../../../components/designSystems/appsmith/help/FileManagerApi";
import OrgApi from "../../../api/OrgApi";
import { SubTable } from "./SubTableCell";
import { useDispatch } from "react-redux";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";

const Pagination = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 15px;

  button {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
  }
  button:hover {
    background: #e9efff;
  }
  .items {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #6c767e;
    margin-right: 10px;
  }
  select {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 15px;
    cursor: pointer;
  }
`;

const Table = forwardRef(
  ({ userColumns, data, renderRowSubComponent }: any, ref) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      page,
      prepareRow,
      visibleColumns,
      gotoPage,
      previousPage,
      nextPage,
      pageCount,
      canNextPage,
      canPreviousPage,
      pageOptions,
      setPageSize,
      setGlobalFilter,
      state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
      {
        columns: userColumns,
        data,
        initialState: {
          sortBy: [
            {
              id: "Update Date",
              desc: true,
            },
          ],
        },
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
    );

    useImperativeHandle(ref, () => ({
      filter(value: string) {
        setGlobalFilter(value || undefined);
      },
    }));
    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr
                key={index}
                className="header"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, index: number) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="header_table">
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div className="sorting_table">
                              <div style={{ height: "10px" }}></div>
                              <ArrowDown></ArrowDown>
                            </div>
                          ) : (
                            <div className="sorting_table">
                              <ArrowUp></ArrowUp>
                              <div style={{ height: "10px" }}></div>
                            </div>
                          )
                        ) : (
                          <div className="sorting_table">
                            <ArrowUp></ArrowUp>
                            <ArrowDown></ArrowDown>
                          </div>
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                // Use a React.Fragment here so the table markup is still valid
                // eslint-disable-next-line react/jsx-key
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index1) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {/* {row.isExpanded ? (
                    <tr>
                      <td className="expanded" colSpan={visibleColumns.length}>
                        <SubTable row={row}></SubTable>
                      </td>
                    </tr>
                  ) : null} */}
                </>
              );
            })}
          </tbody>
        </table>

        <Pagination className="pagination">
          <span className="items">
            Page{" "}
            <span>
              {pageIndex + 1} of {pageOptions?.length}
            </span>{" "}
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}/page
              </option>
            ))}
          </select>
        </Pagination>
      </>
    );
  },
);

Table.displayName = "Table";
export default Table;

export const range = (len: any) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

export function makeData(orgs: any) {
  return orgs
    .filter((elem: any) => elem.applications.length > 0)
    .map((item: any) => {
      return {
        name: item.organization.name,
        create_date: item.organization.createdAt,
        update_date: item.organization.updatedAt,
        buttons: {
          hrefEdit:
            "workspace/" +
            item.applications[0].id +
            "/pages/" +
            item.applications[0].pages[0].id +
            "/edit",
          href:
            "workspace/" +
            item.applications[0].id +
            "/pages/" +
            item.applications[0].pages[0].id,
          id: item.organization.id,
          name: item.organization.name,
          appId: item.applications[0].id,
        },
      };
    });
}

const EditName = styled.div`
  width: 150px;
  .bp3-editable-text {
    height: 16px !important;
    padding: 0px 12px !important;
  }
  span {
    color: black !important;
  }
  input {
    background: white !important;
    color: black !important;
    border: 1px solid #ececec !important;
  }
  .bp3-editable-text {
    background: white !important;
    color: black !important;
  }
  .icon-wrapper {
    display: none !important;
  }
`;

export function DateCell({ value }: any) {
  return (
    <span>
      {value &&
        moment(value)
          .local()
          .format("DD-MM-YYYY hh:mm:ss a")}{" "}
    </span>
  );
}

export function NameCell({ value }: any) {
  return (
    <div>
      <EditName>
        <EditableText
          defaultValue={value}
          editInteractionKind={EditInteractionKind.SINGLE}
          placeholder={"enter name"}
          savingState={value}
          onBlur={value => {
            console.log(value);
          }}
        />
      </EditName>
    </div>
  );
}

const WrapperAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
    width: 45%;
  }
  button:hover {
    background: #e9efff;
  }
`;

export function ButtonCell({ value }: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigation = useCallback(() => {
    history.push(value.href);
  }, [value]);
  const navigationEdit = useCallback(() => {
    dispatch({
      type: ReduxActionTypes.CUR_APP,
      payload: value.appId,
    });
    history.push(value.hrefEdit);
  }, [value]);
  const deleteOrg = (value: any) => {
    dispatch({
      type: ReduxActionTypes.DELETE_ORG_INIT,
      payload: {
        orgId: value.id,
        nameOrg: value.name,
      },
    });
  };
  const refModalDelete = useRef<{ openModal: any }>();
  const openModalDelete = () => {
    refModalDelete?.current?.openModal();
  };
  return (
    <WrapperAction>
      <button onClick={navigationEdit}>edit</button>
      {/* <button onClick={navigation}>launch</button> */}
      <button onClick={openModalDelete}>delete workspace</button>
      <ModalDelete
        ref={refModalDelete}
        name={`workspaces  ${value.name} ?`}
        item={value}
        remove={deleteOrg}
      />
    </WrapperAction>
  );
}
