import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
// import { Modal } from "@material-ui/core";
import { Icon, Label } from "@blueprintjs/core";
import { ColumnTypes } from "../../widgets/TableWidget";
import { Colors } from "../../constants/Colors";
import { MenuColumnWrapper } from "../designSystems/appsmith/TableStyledWrappers";
import { createOrganizationSubmitHandler } from "../../pages/organization/helpers";
import { useDispatch } from "react-redux";
import { Modal, Tabs, Radio, Form, Input, Divider, Button } from "antd";

const WrapperModal = styled.div`
  .provider_group {
    width: 100%;
    display: flex;
    justify-content: space-around;
    aline-items: center;
  }
  .provider_group img {
    margin-right: 10px;
    width: 24px;
  }
  .provider_group label {
    height: fit-content;
  }
  .resource_content {
    line-height: 15px;
    margin-top: 10px;
    text-align: center;
    border-radius: 4px;
  }
  .tab_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tab_info_div {
    width: 100%;
  }
`;

export const CreateOrgModal = forwardRef((props: any, ref) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [provider, setProvider] = useState("");
  const [region, setRegion] = useState("");
  const [resource, setResource] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [type, setTabKey] = useState("trial");
  const { TabPane } = Tabs;
  const [name, setName] = useState("");

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setDisabled(false);
    onReset();
  }, []);

  const handleInput = useCallback(event => {
    setName(event.target.value);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  useImperativeHandle(ref, () => ({
    open() {
      handleOpen();
    },
  }));

  const onProviderChange = (e: any) => {
    setProvider(e.target.value);
  };

  const onRegionChange = (e: any) => {
    setRegion(e.target.value);
  };

  const onResourceChange = (e: any) => {
    setResource(e.target.value);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const tabInfo = (e: any) => {
    console.log(e);
    setTabKey(e);
    form.setFieldsValue({
      type: e,
    });
    onReset();
  };
  // const handleCreate = useCallback(() => {
  //   setDisabled(true);
  //   // console.log(name, "name")
  //   createOrganizationSubmitHandler({ name: name }, dispatch);
  // }, [name]);
  const onFinish = (values: any) => {
    console.log(type);
    setOpen(false);
    onReset();
    createOrganizationSubmitHandler(
      { name: name, provider: provider, region: region, resource: resource },
      dispatch,
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal
      width={650}
      onCancel={handleClose}
      visible={open}
      title="Create Workspace"
      footer={null}
      // onOk={handleCreate}
    >
      <WrapperModal>
        <Form
          form={form}
          preserve={false}
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "name"]}
            label="Name"
            rules={[{ required: true }]}
          >
            <Input onChange={handleInput} value={name} />
          </Form.Item>
          <Divider>Instance Configuration </Divider>
          <Form.Item>
            <Tabs
              defaultActiveKey={type}
              type="card"
              size="small"
              onChange={tabInfo}
            >
              <TabPane className="tab_info" tab="Trial instanse" key="trial">
                <div>
                  The trial instance is available for 7-days after registration.
                  All computational resources are shared among all trial users,
                  this could cause a loss in performance. To achieve the best
                  performance add New Instance to your workspace.
                </div>
                <Divider></Divider>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={disabled}>
                    Create
                  </Button>
                </Form.Item>
              </TabPane>

              <TabPane
                disabled
                className="tab_info"
                tab="New Instance"
                key="new_instanse"
              >
                <div className="tab_info_div">
                  <Form.Item
                    name={["user", "provider"]}
                    label="Cloud provider"
                    rules={[{ required: type == "new_instanse" }]}
                  >
                    <Radio.Group
                      className="provider_group"
                      onChange={onProviderChange}
                      size="large"
                    >
                      <Radio.Button value={"AWS"}>
                        <img
                          src="https://calculator.settlemint.com/assets/icons/amazon-web-services-black.svg"
                          alt="#"
                        />
                        AWS
                      </Radio.Button>
                      <Radio.Button disabled value={"Google"}>
                        {" "}
                        <img
                          src="https://calculator.settlemint.com/assets/icons/google-cloud.svg"
                          alt=""
                        />{" "}
                        Google Cloud
                      </Radio.Button>
                      <Radio.Button disabled value={"Azure"}>
                        {" "}
                        <img
                          src="https://calculator.settlemint.com/assets/icons/azure.svg"
                          alt=""
                        />{" "}
                        Azure
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {provider == "AWS" ? (
                    <Form.Item
                      name={["user", "region"]}
                      label="Region"
                      rules={[{ required: type == "new_instanse" }]}
                    >
                      <Radio.Group
                        className="provider_group"
                        size="large"
                        onChange={onRegionChange}
                      >
                        <Radio.Button value={"Frankfurt"}>
                          Frankfurt
                        </Radio.Button>
                        <Radio.Button disabled value={"Singapore"}>
                          Singapore
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {region ? (
                    <Form.Item
                      name={["user", "resource_pack"]}
                      label="Resource pack"
                      rules={[{ required: type == "new_instanse" }]}
                    >
                      <Radio.Group
                        className="provider_group"
                        size="large"
                        onChange={onResourceChange}
                      >
                        <Radio.Button value={"small"}>
                          <div className="resource_content">
                            <p>
                              <b>Small</b>
                            </p>
                            <small>
                              <p>
                                {" "}
                                € 0.56/h (€ 403.2/mo)
                                <br />
                                excl VAT
                              </p>
                              <Divider></Divider>
                              <p>
                                2 GB Memory
                                <br />
                                1 vCPU
                                <br />
                                5 GB Disc space
                                <br />
                                10 req/s{" "}
                              </p>
                            </small>
                          </div>
                        </Radio.Button>
                        <Radio.Button value={"medium"}>
                          <div className="resource_content">
                            <p>
                              <b>Medium</b>
                            </p>
                            <small>
                              <p>
                                {" "}
                                € 0.56/h (€ 403.2/mo)
                                <br />
                                excl VAT
                              </p>
                              <Divider></Divider>
                              <p>
                                2 GB Memory
                                <br />
                                1 vCPU
                                <br />
                                5 GB Disc space
                                <br />
                                10 req/s{" "}
                              </p>
                            </small>
                          </div>
                        </Radio.Button>
                        <Radio.Button value={"large"}>
                          <div className="resource_content">
                            <p>
                              <b>Large</b>
                            </p>
                            <small>
                              <p>
                                {" "}
                                € 0.56/h (€ 403.2/mo)
                                <br />
                                excl VAT
                              </p>
                              <Divider></Divider>
                              <p>
                                2 GB Memory
                                <br />
                                1 vCPU
                                <br />
                                5 GB Disc space
                                <br />
                                10 req/s{" "}
                              </p>
                            </small>
                          </div>
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </div>
                {resource ? (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabled}
                    >
                      Create
                    </Button>
                  </Form.Item>
                ) : (
                  <></>
                )}
              </TabPane>

              <TabPane
                tab="Available Instances"
                disabled
                key="available_instances"
              >
                Content of card tab 3
              </TabPane>
            </Tabs>
          </Form.Item>
        </Form>
      </WrapperModal>
    </Modal>
  );
});
CreateOrgModal.displayName = "UploadModal";
export default CreateOrgModal;
