import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";

import { Button, Input, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { WrapperItemInput } from "./ModalNodeRed";
import { updateNodeRedEnvApi } from "components/designSystems/appsmith/help/FileManagerApi";
import { AppToaster } from "components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { useDispatch } from "react-redux";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createIdLoading, LoadingItem } from "pages/Editor/DoclLayoutSettings";

export const NodeRedModalEnv = forwardRef((props: any, ref) => {
  console.log(props, "dfdakfh");
  const [isOpen, setIsOpen] = useState(false);
  const [arrayEnv, setArrayEnv] = useState<any>([]);
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  const addItemToEnv = useCallback(() => {
    const newArray = [...arrayEnv, { name: "", value: "" }];
    setArrayEnv(newArray);
  }, [arrayEnv]);
  const changeEnvValue = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].value = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const changeEnvKey = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].name = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const deleteEnv = useCallback(
    (index: number) => {
      const newArray = [...arrayEnv];
      newArray.splice(index, 1);
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  useEffect(() => {
    if (props.item.settings_data.env) {
      setArrayEnv(props.item.settings_data.env);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));

  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      return !prev;
    });
  }, [isOpen, setIsOpen]);

  const closeOverlay = useCallback(() => {
    setIsOpen(false);
    props.item.settings_data.env
      ? setArrayEnv(props.item.settings_data.env)
      : setArrayEnv([]);
  }, [isOpen, setIsOpen]);
  const UpdateUiEnv = async () => {
    setClicked(true);

    const { data } = await updateNodeRedEnvApi({
      orgId: props.item.organizationId,
      name: props.item.name,
      env: arrayEnv,
      datasourceId: props.item.id,
      serviceId: props.item.settings_data.serviceId,
    });
    if (data.data.error) {
      AppToaster.show({
        message: `${data.data.message}`,
        type: ToastType.ERROR,
      });
      setClicked(false);
    } else {
      setClicked(false);
      await dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      toggleOverlay();
      dispatch({
        type: ReduxActionTypes.UPDATE_LAYOUT_ITEM,
        payload: {
          id: JSON.stringify({
            id: props.item.name,
            type: "nodeRed",
            props: props.item.datasourceConfiguration.url,
          }),
          content: (
            <LoadingItem
              url={props.item.datasourceConfiguration.url}
              type={"Loading"}
              title={props.item.name}
              id={createIdLoading({
                id: props.item.name,
                type: "Loading",
                props: props.item.datasourceConfiguration.url,
                subtype: "nodered",
              })}
            />
          ),
          title: props.item.name,
          group: "allowWindow",
          closable: true,
        },
      });
    }
  };
  return (
    <Modal
      width={"550px"}
      okText={"Update ENV"}
      okButtonProps={{ disabled: clicked }}
      title={`Flow Builder - ${props.item.name}`}
      visible={isOpen}
      onOk={UpdateUiEnv}
      onCancel={closeOverlay}
    >
      <label>
        Flow Builder URL
        <Input
          style={{ cursor: "initial" }}
          value={props.item.datasourceConfiguration.url}
          disabled={true}
          type="text"
        />
      </label>

      {arrayEnv.map((item: any, index: number) => {
        return (
          <WrapperItemInput key={index}>
            <div className="wrapper_env">
              <div>
                {!false && (
                  <span
                    className="delete_env"
                    onClick={() => {
                      deleteEnv(index);
                    }}
                  >
                    <CloseCircleOutlined />
                  </span>
                )}
                <div>
                  <span className="title_input">Key</span>
                  <Input
                    value={arrayEnv[index].name}
                    onChange={e => {
                      changeEnvKey(index, e);
                    }}
                    placeholder="Key"
                    // disabled={disabled}
                    type="text"
                  />
                </div>
              </div>
              <div>
                <span className="title_input">Value</span>
                <div>
                  <Input
                    value={arrayEnv[index].value}
                    placeholder="Value"
                    onChange={e => {
                      changeEnvValue(index, e);
                    }}
                    // disabled={disabled}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </WrapperItemInput>
        );
      })}
      <WrapperItemInput>
        <Button type="primary" onClick={addItemToEnv}>
          Add New Env
        </Button>
      </WrapperItemInput>
    </Modal>
  );
});
NodeRedModalEnv.displayName = "NodeRedModalEnv";
