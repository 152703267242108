import Sidebar from "../../components/editorComponents/Sidebar";
import { LayoutData } from "rc-dock";
import { EditorPage, UiEditor, WrapperSettingsWidget } from "./UiEditor";
import ApplicationApi from "../../api/ApplicationApi";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { NodeRedCollapse, WrapperCollapse } from "./Explorer/NodeRedCollapse";
import { SocketCollapse } from "./Explorer/SocketCollapse";
import { IframeCollapse } from "./Explorer/IframeCollapse";
import JSDependencies from "./Explorer/JSDependencies";
import { getTransformer } from "../../components/editorComponents/transformersMenu";
import TransformEditor from "../../components/editorComponents/TransformEditor";
import TransformEditorJS from "../../components/editorComponents/TransformEditorJS";
import styled from "styled-components";
import DebuggerTabs from "../../components/editorComponents/Debugger/DebuggerTabs";
import { UiEditorCollapse } from "./Explorer/UiEditorCollapse";
import { UiDebugCollapse } from "./Explorer/UiDebugCollapse";
import { DbCollapse } from "./Explorer/dbCollapse";
import { ReactComponent as Update } from "../../assets/images/work_space/update.svg";
import { ReactComponent as Copy } from "../../assets/images/work_space/copy.svg";
import { ReactComponent as Info } from "../../assets/images/work_space/info.svg";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { setInterval } from "timers";
import UbosLoader from "pages/common/UbosLoader";
import UbosLoader2 from "pages/common/UbosLoader2";
import { title } from "process";

const WrapperGlobalSidebar = styled.div`
  background: #f3f3f3;
  height: 100%;
  width: 100%;
  color: white;
  position: relative;
  z-index: 4;
  .wrapper_header {
    display: flex;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 32px;
    color: #1e272e;
    justify-content: space-between;
    align-items: center;
    .title {
      width: 69px;
      margin-left: 14px;
      font-weight: 600;
    }
    .wrapper_button {
      padding: 0 0 0 25px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100px;
      svg: hover {
        cursor: pointer;
      }
    }
  }
`;

export const versionId = "1.0.2";
export const UserInfo = () => {
  const dispatch = useDispatch();
  const openUserInfo = useCallback(() => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: "analytic",
        component: "UserA",
      },
    });
  }, []);
  return (
    <div>
      <WrapperCollapse>
        <div
          onClick={openUserInfo}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span style={{ paddingLeft: "14px" }}>Analytic</span>
        </div>
      </WrapperCollapse>
    </div>
  );
};

export const GlobalSidebar = () => {
  const NodeRedRef = useRef<{ close: any }>();
  const IframeRef = useRef<{ close: any }>();
  const DbRef = useRef<{ close: any }>();
  const JSDependenciesRef = useRef<{ close: any }>();
  const handleClose = useCallback(() => {
    NodeRedRef?.current?.close();
    IframeRef?.current?.close();
    DbRef?.current?.close();
    JSDependenciesRef?.current?.close();
  }, [NodeRedRef]);
  return (
    <WrapperGlobalSidebar>
      <div className="wrapper_header">
        <div className="title">SERVICES</div>
        <div className="wrapper_button">
          {/* <Update></Update>
          <Copy onClick={handleClose}></Copy>
          <Info></Info> */}
        </div>
      </div>
      <NodeRedCollapse ref={NodeRedRef} />
      {/*<SocketCollapse />*/}
      <IframeCollapse ref={IframeRef} />
      <DbCollapse ref={DbRef} />
      {/* <JSDependencies ref={JSDependenciesRef} /> */}
      <UiEditorCollapse />
      {/* <UiDebugCollapse /> */}
      {/* <UserInfo /> */}
    </WrapperGlobalSidebar>
  );
};

export const literalItemLayoutNew: any = {
  Console: {
    id: createIdConsole(),
    title: "Debugger",
    content: <DebuggerTabs defaultIndex={1} />,
    group: "allowWindow",
    closable: true,
  },
  Sidebar: {
    id: createIdSidebar(),
    title: "Sidebar",
    content: Sidebar,
    group: "allowWindow",
  },
  ContainerEditor: {
    id: createIdEditorPage(),
    title: "UI Editor",
    content: UiEditor,
    group: "allowWindow",
    closable: true,
  },
  EditorPage: {
    id: creatIdEditorPage(),
    title: "Page",
    content: EditorPage,
    group: "allowWindow",
  },
  // globalSidebar: {
  //   id: createIdGlobalSidebar(),
  //   title: "GlobalSidebar",
  //   content: GlobalSidebar,
  //   group: "allowWindow",
  // },
  SettingsWidget: {
    id: createSettingsWidget(),
    title: "Widget",
    content: WrapperSettingsWidget,
    group: "allowWindow",
  },
};

export const defaultLayoutUi: LayoutData = {
  dockbox: {
    mode: "horizontal",
    children: [
      {
        mode: "vertical",
        children: [
          {
            size: 300,
            id: "Sidebar3",
            tabs: [
              {
                id: createIdSidebar(),
                title: "Sidebar",
                content: Sidebar as any,
                group: "allowWindow",
              },
            ],
          },
        ],
      },
      {
        size: 1000,
        id: "WrapperUiEditor",
        tabs: [
          {
            id: creatIdEditorPage(),
            title: "Page",
            content: EditorPage,
            group: "allowWindow",
          },
        ],
      },
      {
        size: 200,
        id: "WrapperSettingsWidget",
        tabs: [
          {
            id: createSettingsWidget(),
            title: "Widget",
            content: WrapperSettingsWidget,
            group: "allowWindow",
            closable: false,
          },
        ],
      },
    ],
  },
};

export const defaultLayout: LayoutData = {
  dockbox: {
    mode: "horizontal",
    id: "MainContainerFull",
    children: [
      // {
      //   size: 150,
      //   id: "WrapperGlobalSidebar",
      //   tabs: [
      //     {
      //       id: createIdGlobalSidebar(),
      //       title: "GlobalSidebar",
      //       content: GlobalSidebar,
      //       group: "allowWindow",
      //     },
      //   ],
      // },
      // {
      //   size: 1400,
      //   id: "WrapperEditorPage",
      //   tabs: [
      //     {
      //       id: createIdEditorPage(),
      //       title: "ContainerEditor",
      //       content: UiEditor,
      //       group: "allowWindow",
      //       closable: true,
      //     },
      //   ],
      // },
      // {
      //   size: 200,
      //   id: "WrapperConsole",
      //   tabs: [
      //     {
      //       id: createIdConsole(),
      //       title: "Debugger",
      //       content: <DebuggerTabs defaultIndex={1} />,
      //       group: "allowWindow",
      //       closable: false,
      //     },
      //   ],
      // },
    ],
  },
};

export const groups = {
  allowWindow: {
    floatable: true,
    newWindow: true,
    maximizable: true,
    closable: true,
  },
};

export function createSettingsWidget() {
  const id = { id: "SettingsWidget", type: "SettingsWidget" };
  return JSON.stringify(id);
}

export function creatIdTransformersMenu() {
  const id = { id: "TransformersMenu", type: "TransformersMenu" };
  return JSON.stringify(id);
}

export function creatIdEditorPage() {
  const id = { id: "EditorPage", type: "EditorPage" };
  return JSON.stringify(id);
}

export function createIdTransformers() {
  const id = { id: "Transformers", type: "Transformers" };
  return JSON.stringify(id);
}

// export function createIdGlobalSidebar() {
//   const id = { id: "globalSidebar", type: "globalSidebar" };
//   return JSON.stringify(id);
// }

export function createIdEditorPage() {
  const id = { id: "ContainerEditor", type: "ContainerEditor" };
  return JSON.stringify(id);
}

export function createIdSidebar() {
  const id = { id: "Sidebar", type: "Sidebar" };
  return JSON.stringify(id);
}

export function createIdTransformer(item: any) {
  const id = { id: item.id, type: "transformer" };
  return JSON.stringify(id);
}

export function createIdWrapperEditorPage() {
  const id = { id: "wrapperEditor", type: "wrapperEditor" };
  return JSON.stringify(id);
}

export function createIdNodeRed(item: any) {
  const id = { id: item.title || item.id, type: "nodeRed", props: item.props };
  return JSON.stringify(id);
}
export function createIdLoading(item: any) {
  const id = {
    id: item.title || item.id,
    type: "Loading",
    props: item.props,
    subtype: item.subtype,
  };
  return JSON.stringify(id);
}

export function createIdConsole() {
  const id = { id: "Console", type: "Console" };
  return JSON.stringify(id);
}
export function createIdUserA() {
  const id = { id: "UserInfo", type: "userInfo" };
  return JSON.stringify(id);
}
export function createIdUserPanel() {
  const id = { id: "DeploymenManager", type: "DeploymenManager" };
  return JSON.stringify(id);
}
export function createIdAdminPanel() {
  const id = { id: "AdminPanel", type: "AdminPanel" };
  return JSON.stringify(id);
}

export function clearTransformer(item: any) {
  deleteOneItem(item.dockbox);
  deleteOneItem(item.floatbox);
  deleteOneItem(item.maxbox);
  deleteOneItem(item.windowbox);
  return item;
}

export function deleteOneItem(item: any) {
  if (item.children) {
    for (const sub of item.children) {
      if (sub.tabs) {
        const index = [];
        sub.tabs = sub.tabs.filter((item: any) => {
          let inform;
          try {
            inform = JSON.parse(item.id);
          } catch (e) {}
          if (!inform || (inform && inform.type === "transformer")) {
            return false;
          }
          return true;
        });
      } else if (sub.children) {
        deleteOneItem(sub);
      }
    }
  }
  return item;
}

export function savedSettings(layoutRef: any, currentApplicationID: any) {
  if (layoutRef.current) {
    const res = ApplicationApi.updateLayoutApplication(
      currentApplicationID,
      clearTransformer(layoutRef?.current?.saveLayout()),
    );
  }
}

export function savedWidth(currentApplicationID: any, width: number) {
  const res = ApplicationApi.updateLayoutSidebarWidth(currentApplicationID, {
    sidebarWidth: width,
  });
}

export function savedSettingsUi(layoutRef: any, currentApplicationID: any) {
  const res = ApplicationApi.updateUiLayoutApplication(
    currentApplicationID,
    clearTransformer(layoutRef?.current?.saveLayout()),
  );
}

function findOneItem(item: any) {
  if (item.children) {
    for (const sub of item.children) {
      if (sub.tabs) {
        for (const tab of sub.tabs) {
          let inform;
          try {
            inform = JSON.parse(tab.id);
          } catch (e) {
            inform = { type: "transform" };
          }

          if (literalItemLayoutNew[inform.type]) {
            for (const key in literalItemLayoutNew[inform.type]) {
              tab[key] = literalItemLayoutNew[inform.type][key];
            }
          }
          if (sub.x < 0) {
            sub.w = 500;
            sub.x = 10;
          }
          if (sub.y < 0) {
            sub.h = 500;
            sub.y = 50;
          }
          if (sub.x > window.innerWidth - 10) {
            sub.w = 500;
            sub.x = 10;
          }
          if (sub.y > window.innerHeight - 10) {
            sub.h = 500;
            sub.y = 50;
          }

          if (literalItemLayoutCreate[inform.type]) {
            const functionCreate = literalItemLayoutCreate[inform.type](inform);
            for (const key in functionCreate) {
              tab[key] = functionCreate[key];
            }
          }
        }
      } else if (sub.children) {
        findOneItem(sub);
      }
    }
  }
  return item;
}

const literalItemLayoutCreate: any = {
  nodeRed: (e: any) => {
    return {
      id: createIdNodeRed(e),
      content: <NodeRed url={e.props} />,
      title: e.id,
      group: "allowWindow",
      closable: true,
    };
  },
  transformer: (e: any) => {
    return {
      id: "delete",
      closable: true,
    };
  },
  userInfo: () => {
    return {
      id: createIdUserA(),
      content: <UserA />,
      title: "analytic",
      group: "allowWindow",
      closable: true,
    };
  },
  AdminPanel: () => {
    return {
      id: createIdAdminPanel(),
      content: <AdminPanelU />,
      title: "AdminPanel",
      group: "allowWindow",
      closable: true,
    };
  },
  DeploymenManager: () => {
    return {
      id: createIdUserPanel(),
      content: <UserPanelU />,
      title: "DeploymenManager",
      group: "allowWindow",
      closable: true,
    };
  },
  Loading: (e: any) => {
    return {
      id: createIdLoading(e),
      content: (
        <LoadingItem
          subtype={e.subtype}
          url={e.props}
          type={"Loading"}
          id={createIdLoading(e)}
          title={e.id}
        />
      ),
      title: e.id,
      group: "allowWindow",
      closable: true,
    };
  },
};

export function replaceItemDockLayout(item: any) {
  if (item) {
    const floatbox = findOneItem(item.floatbox);
    const windowbox = findOneItem(item.windowbox);
    if (floatbox.children.length > 0 && windowbox.children.length > 0) {
      floatbox.children[0].tabs.push();
      floatbox.children[0].tabs = [
        ...floatbox.children[0].tabs,
        ...windowbox.children[0].tabs,
      ];
    } else if (
      floatbox.children.length === 0 &&
      windowbox.children.length > 0
    ) {
      floatbox.children = windowbox.children;
    }
    const content = {
      dockbox: findOneItem(item.dockbox),
      floatbox: floatbox,
      maxbox: findOneItem(item.maxbox),
    };
    return content;
  } else {
    return false;
  }
}

export const NodeRed = (props: { url: string }) => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={props.url + "?access_token=" + keycloakAccessToken}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const UserA = () => {
  const user_namespace = useSelector(
    (state: AppState) => state.ui.users.currentUser?.user_namespace,
  );
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={"https://admin.ubraine.com/" + "?user_namespace=" + user_namespace}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const UserPanelU = () => {
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const org_id = useSelector((state: AppState) => state.ui.orgs.currentOrg.id);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://deployment-manager.${location.host}/home?access_token=${keycloakAccessToken}&orgId=${org_id}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
export const AdminPanelU = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://sys-adm.${location.host}/`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export const LoadingItem = (props: any) => {
  const dispatch = useDispatch();
  const test = {
    id: props.id,
    content: (
      <LoadingItem
        url={props.props}
        type={"Loading"}
        id={createIdLoading(props)}
        title={props.id || props.name}
      />
    ),
    title: props.title,
    group: "allowWindow",
    closable: true,
  };
  dispatch({
    type: ReduxActionTypes.UPDATE_LAYOUT_ITEM,
    payload: test,
  });
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* <UbosLoader /> */}
      <UbosLoader2 />
      {/* <span>Loading...</span> */}
    </div>
  );
};

export const ItemTransformer = (props: any) => {
  const item: any = useSelector((state: AppState) => {
    return getTransformer(state).find(items => {
      return items.widgetId == props.item.widgetId;
    });
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {!item?.typeFunction ? (
        <>
          <div style={{ height: "65px" }}>
            <TransformEditor
              property={"expectation"}
              height={"35px"}
              title={"Dependent fields"}
              value={item?.expectation}
              items={item}
            />
          </div>
          <div style={{ height: "165px" }}>
            <TransformEditor
              property={"text"}
              height={"153px"}
              title={"Functions"}
              value={item?.text}
              items={item}
            />
          </div>
        </>
      ) : (
        <TransformEditorJS
          property={"text"}
          height={100}
          title={"Transformers"}
          value={item?.text}
          items={item}
        />
      )}
    </div>
  );
};
