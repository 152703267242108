import { Button, Icon, Overlay } from "@blueprintjs/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  createNodeRed,
  getList,
  getTypeSystemNodeRed,
} from "../../../../components/designSystems/appsmith/help/FileManagerApi";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../reducers";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { storeAsDatasourceApi } from "../../../../actions/datasourceActions";
import ClickAwayListener from "react-click-away-listener";
import ApiDatabase from "../../../../api/ApiDatabase";
import { ModalInform } from "../../../../components/designSystems/component/ModalInform";
import { AppToaster } from "../../../../components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { CloseIcon, WrapperModal } from "./ModalNodeRed";

export const WrapperItemInput = styled.div`
  margin-top: 20px;
  width: 500px;
  input {
    width: 100%;
  }
  .wrapper_checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .checkbox {
    width: 18px;
    margin-left: 20px;
  }
`;
export const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatabaseModal = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [typeDb, setTypeDb] = useState("");
  const [typesDb, setTypesDb] = useState([]);
  const [tagDb, setTagDb] = useState("latest");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [inform, setInform] = useState(false);
  const [viewType, setViewType] = useState("ClusterIP");
  const refModalInform = useRef<{ openModal: any }>();
  const dispatch = useDispatch();
  const currentApplicationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.id,
  );
  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      if (prev) {
        setName("");
        setTypeDb(typesDb[0] || "mongo");
        setUserName("");
        setPassword("");
        setTagDb("latest");
        setViewType("ClusterIP");
      }
      return !prev;
    });
  }, [isOpen]);

  const openModalInform = useCallback(() => {
    refModalInform?.current?.openModal();
  }, []);

  const createDatabase = useCallback(async () => {
    try {
      const { data } = await ApiDatabase.dbCreate({
        application_id: currentApplicationId,
        typeDB: typeDb,
        tag_DB: tagDb,
        database_name: name,
        username: userName,
        password: password,
        view_type: viewType,
      });
      if (data.error) {
        setInform(data.message);
        openModalInform();
        toggleOverlay();
      }

      toggleOverlay();
    } catch (e) {
      toggleOverlay();
    }
  }, [name, password, tagDb, typeDb, userName, viewType, currentApplicationId]);

  useEffect(() => {
    (async () => {
      try {
        const res = await ApiDatabase.dbTypes();
        setTypeDb(res.data[0]);
        setTypesDb(res.data);
      } catch (e) {}
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));
  return (
    <div>
      {isOpen ? (
        <WrapperModal>
          <ClickAwayListener onClickAway={toggleOverlay}>
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <div style={{ padding: "40px", background: "white" }}>
                  <div className="title">New Data Source</div>
                  <CloseIcon>
                    <Icon
                      onClick={toggleOverlay}
                      iconSize={16}
                      color={"black"}
                      icon="cross"
                    />
                  </CloseIcon>
                  <WrapperItemInput>
                    <span className="title_input">Database Name</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="Enter Database name"
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">User Name</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        placeholder="Enter User Name"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">Type Data Source</span>
                    <div>
                      <select
                        name=""
                        id=""
                        onChange={e => setTypeDb(e.target.value)}
                        value={typeDb}
                      >
                        {typesDb.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">Tag Database</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        placeholder="Enter Url Iframe"
                        value={tagDb}
                        onChange={e => setTagDb(e.target.value)}
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input"> Password</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        placeholder="Enter Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </WrapperItemInput>
                  {/* <WrapperItemInput>
                    <span className="title_input wrapper_checkbox">
                      External access to the Data Source{" "}
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={viewType == "ClusterIP" ? false : true}
                        onChange={e => {
                          setViewType(
                            e.target.checked ? "NodePort" : "ClusterIP",
                          );
                        }}
                      />
                    </span>
                  </WrapperItemInput> */}
                  <WrapperItemInput>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="button"
                        onClick={createDatabase}
                        disabled={
                          userName.length === 0 &&
                          password.length === 0 &&
                          tagDb.length === 0 &&
                          typesDb.length === 0 &&
                          name.length === 0
                        }
                      >
                        Create Database
                      </button>
                    </div>
                  </WrapperItemInput>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </WrapperModal>
      ) : (
        ""
      )}
      <ModalInform ref={refModalInform} inform={inform}></ModalInform>
    </div>
  );
});
DatabaseModal.displayName = "DatabaseModal";
