import { Button, Icon, Overlay } from "@blueprintjs/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import {
  createNodeRed,
  getList,
  getTypeSystemNodeRed,
} from "../../../../components/designSystems/appsmith/help/FileManagerApi";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../reducers";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { storeAsDatasourceApi } from "../../../../actions/datasourceActions";
import ClickAwayListener from "react-click-away-listener";
import ApiDatabase from "../../../../api/ApiDatabase";
import { CloseIcon } from "./ModalNodeRed";

export const WrapperItemInput = styled.div`
  margin-top: 20px;
  width: 500px;
  input {
    width: 100%;
  }
`;
export const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WrapperModal = styled.div`
  position: fixed;
  z-index: 55555;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  color: black;
  span {
    padding-top: 10px;
    display: block;
  }
`;

export const DatabaseShowModal = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      return !prev;
    });
  }, [isOpen]);

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));
  return (
    <div>
      {isOpen ? (
        <WrapperModal>
          <ClickAwayListener onClickAway={toggleOverlay}>
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <div
                  style={{
                    padding: "40px",
                    background: "white",
                    position: "relative",
                  }}
                >
                  <CloseIcon>
                    <Icon
                      onClick={toggleOverlay}
                      iconSize={16}
                      color={"black"}
                      icon="cross"
                    />
                  </CloseIcon>
                  <WrapperItemInput>
                    <div>Host</div>
                    <div>
                      <span>{props.item.datasourceConfiguration.host}</span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>Name</div>
                    <div>
                      <span>
                        {" "}
                        {props.item.datasourceConfiguration.database_name}
                      </span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>Connect options</div>
                    <div>
                      <span>authSource=admin</span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>User Name</div>
                    <div>
                      <span>{props.item.datasourceConfiguration.username}</span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>Typ Database</div>
                    <div>
                      <span>{props.item.datasourceConfiguration.typeDB}</span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>Tag Database</div>
                    <div>
                      <span>{props.item.datasourceConfiguration.tag_DB}</span>
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div>Password</div>
                    <div>
                      <span>{props.item.datasourceConfiguration.password}</span>
                    </div>
                  </WrapperItemInput>
                  {props.item.conection_string && (
                    <WrapperItemInput>
                      <div>Connection String</div>
                      <div>
                        <span style={{ wordBreak: "break-word" }}>
                          {props.item.datasourceConfiguration.conection_string}
                        </span>
                      </div>
                    </WrapperItemInput>
                  )}
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </WrapperModal>
      ) : (
        ""
      )}
    </div>
  );
});
DatabaseShowModal.displayName = "DatabaseShowModal";
