import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { IconName, IconNames } from "@blueprintjs/icons";
import { Button, Collapse, Icon, Overlay, Tooltip } from "@blueprintjs/core";
import { Help, ListItem, Name, Version } from "./JSDependencies";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { IframeModal } from "./PluginGroup/IframeModal";
import { ReactComponent as CopyIcon } from "../../../assets/icons/control/copy.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/control/delete.svg";
import { ReactComponent as Add } from "../../../assets/images/work_space/add.svg";
import { AppToaster } from "../../../components/editorComponents/ToastComponent";
import {
  AddNodeRed,
  Info,
  NodeRedCollapse,
  WrapperCollapse,
  WrapperItemCollapse,
} from "./NodeRedCollapse";
import { ReactComponent as Close } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as Open } from "../../../assets/images/work_space/open.svg";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";

export const IframeCollapse = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const openModal = () => {
    childRef?.current?.openModal();
  };

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));

  const dispatch = useDispatch();
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const icon = isOpen ? <Open></Open> : <Close></Close>;

  const openIframe = useCallback((item: any) => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: item.title,
        component: "nodeRed",
        props: item.props,
      },
    });
  }, []);

  const deleteIframe = useCallback(
    async (item: any) => {
      try {
        const newIframe = currentApplication?.global_setting?.iframe.filter(
          (iframe: any) => {
            return item.title !== iframe.title;
          },
        );
        dispatch({
          type: ReduxActionTypes.UPDATE_GLOBAL_APPLICATION_SETTINGS,
          payload: {
            id: currentApplication?.id,
            name: "iframe",
            value: newIframe,
          },
        });
        const deleteId = JSON.stringify({
          id: item.title,
          type: "nodeRed",
          props: item.props,
        });
        dispatch({
          type: ReduxActionTypes.REMOVE_LAYOUT_ITEM,
          payload: {
            id: deleteId,
            title: item.title,
            component: "nodeRed",
            props: item.props,
          },
        });
      } catch (e) {}
    },
    [currentApplication],
  );
  const childRef = useRef<{ openModal: any; setValue: any }>();
  const refModalsDelete = useRef<any>({});
  const openModalDelete = useCallback(index => {
    if (refModalsDelete) {
      refModalsDelete.current[index].openModal();
    }
  }, []);
  const handleCollapse = useCallback(async () => {
    setIsOpen(prevState => !prevState);
  }, []);
  return (
    <WrapperItemCollapse>
      <WrapperCollapse className={`${isOpen && "active"}`}>
        <div
          onClick={handleCollapse}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="icon_open_close">{icon}</div>
          <span style={{ paddingLeft: "4px" }}>External app</span>
        </div>
        <AddNodeRed onClick={openModal}>
          <Add></Add>
        </AddNodeRed>
      </WrapperCollapse>
      <Collapse isOpen={isOpen}>
        {currentApplication?.global_setting?.iframe?.map(
          (item: any, index: number) => {
            return (
              <ListItem key={item.title}>
                <Name onClick={() => openIframe(item)}>
                  <span className="dots">{item.title}</span>
                </Name>
                <Info className="info_collapse">
                  <DeleteIcon
                    width={"20px"}
                    onClick={() => {
                      openModalDelete(index);
                    }}
                  ></DeleteIcon>
                  <ModalDelete
                    ref={element => (refModalsDelete.current[index] = element)}
                    name={`Iframe  ${item.title}`}
                    item={item}
                    remove={deleteIframe}
                  />
                  <CopyIcon
                    width={"20px"}
                    onClick={() => {
                      navigator && navigator.clipboard.writeText(item.props);
                      AppToaster.show({
                        type: "success",
                        message: "Copied Url",
                      });
                    }}
                  ></CopyIcon>
                </Info>
              </ListItem>
            );
          },
        )}
      </Collapse>
      <IframeModal ref={childRef} />
    </WrapperItemCollapse>
  );
});
IframeCollapse.displayName = "IframeCollapse";
