import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "selectors/usersSelectors";
import styled from "styled-components";
import { ReactComponent as Bell } from "../../assets/images/logo/bell.svg";
import StorageIcon from "../../assets/images/work_space/tadiran-32x32.png";
import { ReactComponent as ArrowCollapse } from "../../assets/images/work_space/arrowdownCollapse.svg";
import StyledHeader from "components/designSystems/appsmith/StyledHeader";
import { AppState } from "reducers";
import { User, ANONYMOUS_USERNAME } from "constants/userConstants";
import {
  AUTH_LOGIN_URL,
  APPLICATIONS_URL,
  BUILDER_PAGE_URL,
} from "constants/routes";
import Button from "components/editorComponents/Button";
import history from "utils/history";
import ProfileDropdown from "./ProfileDropdown";
import { ReactComponent as Arrow } from "../../assets/images/work_space/arrow-header.svg";

import {
  getApplicationList,
  getAppMode,
  getIsSavingAppName,
} from "../../selectors/applicationSelectors";
import {
  ApplicationPayload,
  ReduxActionTypes,
} from "../../constants/ReduxActionConstants";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "../../selectors/editorSelectors";
import EditableTextWrapper from "../../components/ads/EditableTextWrapper";
import {
  EditInteractionKind,
  SavingState,
} from "../../components/ads/EditableText";
import { updateApplication } from "../../actions/applicationActions";
import { LinkTo } from "@storybook/addon-links";
import ClickAwayListener from "react-click-away-listener";
import { Modal } from "@material-ui/core";
import { VersionModal } from "../Editor/VersionModal";
import ApplicationApi from "../../api/ApplicationApi";
import UploadModal from "../../components/designSystems/appsmith/help/UploadModal";
import {
  getPath,
  importJson,
  uploadApi,
} from "../../components/designSystems/appsmith/help/FileManagerApi";
import posthog from "posthog-js";

const StyledPageHeader = styled(StyledHeader)`
  position: fixed;
  top: 0;
  z-index: 12;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px;
  padding-left: 9px;
  height: 32px;
  background: #dddddd;

  .deploy_button {
    background: #3b82ff;
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    font-family: "Roboto";
    border: none;
    margin-right: 56px;
    cursor: pointer;
  }
  .deploy_button:hover {
    background: #2f79fc;
  }
`;
const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper_profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    color: #6c767e;
    font-size: 14px;
    line-height: 22px;
    .user_name {
      margin-left: 10px;
    }
  }

  .wrapper_bell {
    position: relative;
    div {
      position: absolute;
      top: -2px;
      right: -5px;
      height: 16px;
      display: flex;
      border-radius: 10px;
      background: #db385a;
      padding: 0px 3px;
      font-size: 11px;
      line-height: 16px;
      color: #ffffff;
    }
  }
`;
const WrapperSettings = styled.div`
  display: flex;
  justify-content: space-between;
  width: 430px;
  margin-right: 130px;
  button {
    padding: 4px 16px;
    background: #ececec;
    border-radius: 4px;
    font-family: "Roboto";
    color: #495870;
    border: none;
    font-size: 14px;
    line-height: 16px;
    height: 24px;
    cursor: pointer;
  }
`;
const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    font-family: "Roboto";
    font-size: 12px;
    line-height: 12px;
    color: #6c767e;

    .workspace_name {
      color: #004cbe;
    }
    .open_nav{
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      }
    }
    .nav {
      position:absolute;
      background:#dddddd;
      display:flex;
      flex-direction:column;
      min-width: 120px;
        a {
          color: #004cbe;
          text-decoration: none;
          height: 25px;
          padding: 5px;
          display:flex;
          align-item:center;
        }
        a:hover{
          background:#f3f3f3;
        }
     }
    svg {
      margin-right: 3px;
      margin-left: 3px;
    }
  }
`;

const StyledDropDownContainer = styled.div``;

const LogoImg = styled.img`
  max-width: 110px;
  height: 1.675rem;
`;

type PageHeaderProps = {
  user?: User;
  appMode?: string;
  pageName?: string;
  applicationId?: string;
  publishApplication: (appId: string) => void;
  currentApplication?: ApplicationPayload;
  location?: any;
};

export const PageHeader = (props: PageHeaderProps) => {
  const { user, currentApplication, applicationId } = props;
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(false);
  const [pages, setPages] = useState([]);
  const [publishVersion, setPublishVersion] = useState("");
  const dispatch = useDispatch();
  const isSavingName = useSelector(getIsSavingAppName);
  const pageId = useSelector(getCurrentPageId);
  const applicationList = useSelector(getApplicationList);

  const queryParams = new URLSearchParams(location.search);

  const handleClose = useCallback(() => {
    setVersion(false);
  }, []);
  // useEffect(() => {
  //   posthog.init("phc_19DnKoKNvKHlF6zu5tCrY4CxAysRGT30otBOYyTqoLb", {
  //     api_host: "https://posthog.ubos.tech",
  //   });
  //   if (user && user.email) {
  //     posthog.identify(user.name, { email: user.email });
  //   }
  // }, [user]);

  let loginUrl = AUTH_LOGIN_URL;

  const handleDefaultSettings = useCallback(async () => {
    try {
      const a = await ApplicationApi.reSettingsLayout(applicationId || "");
      location.reload();
    } catch (e) {
      location.reload();
    }
  }, [applicationId]);

  const exportApp = useCallback(async () => {
    try {
      window.location.href =
        window.location.origin + "/api/get_json/" + applicationId;
    } catch (e) {
      location.reload();
    }
  }, [applicationId]);

  const importApp = useCallback(() => {
    modalUpload.current.open();
  }, [applicationId]);

  if (queryParams.has("redirectTo")) {
    loginUrl += `?redirectTo=${queryParams.get("redirectTo")}`;
  }
  const handleOpenVersion = useCallback(() => {
    setVersion(true);
  }, []);
  const updateApplicationDispatch = (id: string, data: { name: string }) => {
    dispatch(updateApplication(id, data));
  };
  const handleNav = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const modalUpload = useRef({
    open: () => {
      console.log();
    },
    close: () => {
      console.log();
    },
  });

  const upload = useCallback(
    async file => {
      const form = new FormData();
      form.append("file", file);
      form.append("orgId", currentApplication?.orgId || "");
      // console.log(BUILDER_PAGE_URL("asd", "asd123"));
      try {
        const { data } = (await importJson(form)) as any;
        if (data) {
          window.location.href =
            window.location.origin +
            BUILDER_PAGE_URL(data.data.appId, data.data.pagId);
        }
        modalUpload.current.close();
      } catch (e) {
        modalUpload.current.close();
      }
    },
    [currentApplication, modalUpload],
  );

  const editPage = props?.location?.pathname.indexOf("/edit") !== -1;
  return (
    <StyledPageHeader>
      <HeaderSection>
        <Link to={APPLICATIONS_URL}>
          <img src={StorageIcon} alt="App crashed" />
        </Link>
        {editPage && (
          <div className="name">
            <span>HOME</span>
            <Arrow></Arrow>
            <span className="workspace_name">
              {currentApplication?.orgname}
            </span>
            {/* <ClickAwayListener
              onClickAway={() => {
                setOpen(false);
              }}
            >
              <span>
                <div className="workspace_name open_nav" onClick={handleNav}>
                  {" "}
                  ({currentApplication?.name}
                  <ArrowCollapse></ArrowCollapse>)
                </div>
                <div className="nav">
                  <div>
                    {open &&
                      currentApplication?.appnames?.map(
                        (item: any, index: number) => {
                          return (
                            <a
                              key={index}
                              href={`/workspace/${item.id}/pages/${item.page_default}/edit`}
                            >
                              {item.name}
                            </a>
                          );
                        },
                      )}
                  </div>
                </div>
              </span>
            </ClickAwayListener> */}
          </div>
        )}
      </HeaderSection>

      {editPage && (
        <WrapperSettings>
          {/* <button onClick={importApp}>Import app</button> */}
          {/* <UploadModal
            ref={modalUpload}
            uploaded={upload}
            type={"JSON"}
            importApp={true}
          /> */}
          {/* <button onClick={exportApp}>Export app</button> */}
          {/* <button onClick={handleDefaultSettings}>Default settings</button> */}
          {/* <div>
            <button onClick={handleOpenVersion}>Version</button>
            <Modal
              open={version}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <VersionModal
                pageId={pageId}
                currentApplication={currentApplication}
                publishVersion={publishVersion}
                setPublishVersion={setPublishVersion}
                closeModal={handleClose}
              />
            </Modal>
          </div> */}
        </WrapperSettings>
      )}

      {/* {editPage && (
        <button
          className="deploy_button"
          onClick={() => {
            props.publishApplication(applicationId || "");
          }}
        >
          Deploy
        </button>
      )} */}
      {user && (
        <StyledDropDownContainer>
          {user.username === ANONYMOUS_USERNAME ? (
            <Button
              filled
              text="Sign In"
              intent={"primary"}
              size="small"
              onClick={() => history.push(loginUrl)}
            />
          ) : (
            <Profile>
              <div className="wrapper_bell">
                {/* <Bell></Bell>
                <div>
                  <span>11</span>
                </div> */}
              </div>

              <div className="wrapper_profile">
                <ProfileDropdown userName={user.username} />
                <div className="user_name">{user.username}</div>
              </div>
            </Profile>
          )}
        </StyledDropDownContainer>
      )}
    </StyledPageHeader>
  );
};

const mapStateToProps = (state: AppState) => ({
  appMode: getAppMode(state),
  user: getCurrentUser(state),
  pageName: state.ui.editor.currentPageName,
  applicationId: getCurrentApplicationId(state),
  currentApplication: state.ui.applications.currentApplication,
});

const mapDispatchToProps = (dispatch: any) => ({
  publishApplication: (applicationId: string) => {
    dispatch({
      type: ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      payload: {
        applicationId,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
