import { Icon, Overlay } from "@blueprintjs/core";
import { Button, Input, Modal } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { AppToaster } from "../../../../components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import {
  createNodeRed,
  createUiApi,
  getList,
  getRealease,
  getTypeSystemNodeRed,
  getUiTags,
} from "../../../../components/designSystems/appsmith/help/FileManagerApi";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../reducers";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { storeAsDatasourceApi } from "../../../../actions/datasourceActions";
import ClickAwayListener from "react-click-away-listener";
import { CloseIcon, WrapperModal } from "./ModalNodeRed";
import { CloseCircleOutlined } from "@ant-design/icons";

export const WrapperItemInput = styled.div`
  margin-top: 20px;
  min-width: 400px;
  input {
    width: 100%;
  }
  .validation {
    color: red;
  }
  .wrapper_env {
    position: relative;
    display: flex;
    justify-content: space-between;
    .delete_env {
      position: absolute;
      right: -3px;
      top: -5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      align-items: center;
      text-align: center;
    }
    .wrapper_item {
      width: 49%;
    }
  }
`;
export const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UiModal = forwardRef((props: any, ref) => {
  const [arrayEnv, setArrayEnv] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [systemType, setSystemType] = useState("db");
  const [release, setRelease] = useState("");
  const [releasesVersion, setReleases] = useState([]);
  const [uitag, setUitag] = useState("");
  const [uitags, setUitags] = useState([]);
  const [url, setUrl] = useState("");
  const [clicked, setClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [fivicon, setFivicon] = useState("");

  const dispatch = useDispatch();
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.orgs.currentOrg.id,
  );
  const dockLayoutItem = useSelector((state: AppState) => {
    return state.layout.dockLayoutItem;
  });
  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      if (prev) {
        setName("");
        setUrl("");
        setArrayEnv([]);
        setTitle("");
        setFivicon("");
      }
      return !prev;
    });
  }, [isOpen, setIsOpen]);
  const validation = useMemo(() => {
    let validEnv = true;
    for (const item of arrayEnv) {
      if (item.name.length === 0 || item.value.length === 0) {
        validEnv = false;
        break;
      }
    }
    return name.length > 0 && validEnv;
  }, [name, arrayEnv]);

  const validationName = useMemo(() => {
    const pattern = /^[a-zA-Z0-9]*$/;
    const patternNumber = /^[0-9]*$/;
    const patternText = /^[a-zA-Z]*$/;

    if (name.length > 0) {
      if (!patternText.test(name[0])) {
        return false;
      }
    }

    return (
      name.length > 0 &&
      pattern.test(name) &&
      name.length < 14 &&
      !patternNumber.test(name)
    );
  }, [name]);
  const addItemToEnv = useCallback(() => {
    const newArray = [...arrayEnv, { name: "", value: "" }];
    setArrayEnv(newArray);
  }, [arrayEnv]);
  const changeEnvValue = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].value = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const changeEnvKey = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].name = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const deleteEnv = useCallback(
    (index: number) => {
      const newArray = [...arrayEnv];
      newArray.splice(index, 1);
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );

  const layoutRef = useRef<any>(null);

  // const createNewApplication = (
  //    applicationName: string,
  //    orgId: string,
  //    isMobile = false,
  // ) => {
  //    console.log(applicationName, orgId);
  //    return dispatch({

  //    });
  // };
  const GetVersion = async () => {
    const data = await getRealease({ type: "db" });

    setRelease(data.data[0].value);
    setReleases(data.data);
  };
  const UiTags = async () => {
    const response = await getUiTags();
    setUitags(response.data.results);
    setUitag(response.data.results[0].name);
  };
  useEffect(() => {
    GetVersion();
    UiTags();
  }, []);
  const createUi = async () => {
    setClicked(true);
    const { data } = await createUiApi({
      orgId: currentOrg,
      name: name,
      env: arrayEnv,
      type: systemType,
      release: release,
      front_tag: uitag,
      title: title,
      fivicon: fivicon,
    });
    if (data.data.error) {
      AppToaster.show({
        message: `${data.data.message}`,
        type: ToastType.ERROR,
      });
      setClicked(false);
    } else {
      toggleOverlay();
      setClicked(false);
      dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      const newItem = {
        id: data.data.name,
        component: "Loading",
        title: data.data.name,
        props:
          "https://" + data.data.datasourceConfiguration.url + "/home/edit/",
        subtype: "UI",
      };
      dispatch({
        type: ReduxActionTypes.SET_LAYOUT_ITEM,
        payload: newItem,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));
  return (
    <div>
      {isOpen ? (
        <WrapperModal>
          <ClickAwayListener onClickAway={toggleOverlay}>
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <div style={{ padding: "40px", background: "white" }}>
                  <div className="title">New Ui</div>
                  <CloseIcon>
                    <Icon
                      onClick={toggleOverlay}
                      iconSize={16}
                      color={"black"}
                      icon="cross"
                    />
                  </CloseIcon>
                  <WrapperItemInput>
                    <span className="title_input">Ui name</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="Enter Ui name"
                      />
                    </div>
                    {name.length != 0 && !validationName && (
                      <div className="validation">
                        the name must be without spaces and not exceed 14
                        characters and first symbol must be a letter
                      </div>
                    )}
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">Title</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Enter Ui Title"
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">Favicon</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        value={fivicon}
                        onChange={e => setFivicon(e.target.value)}
                        placeholder="Favicon url"
                      />
                    </div>
                  </WrapperItemInput>

                  <WrapperItemInput>
                    <span className="title_input">Ui Image(Front)</span>
                    <WrapperSelect>
                      <select
                        value={uitag}
                        onChange={e => setUitag(e.target.value)}
                      >
                        {uitags?.map((item: any, index: number) => {
                          return (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </WrapperSelect>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">System type(Node-red)</span>
                    <WrapperSelect>
                      <select
                        placeholder="Enter Type"
                        value={systemType}
                        onChange={async e => {
                          setSystemType(e.target.value);
                          const response = await getRealease({
                            type: e.target.value,
                          });
                          setReleases(response.data);
                          setRelease(response.data[0].value);
                        }}
                      >
                        <option value={"db"}>With DB</option>
                        <option value={"noDb"}>No Db</option>
                      </select>
                    </WrapperSelect>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">
                      System Releases(Node-red)
                    </span>
                    <WrapperSelect>
                      <select
                        value={release}
                        onChange={e => setRelease(e.target.value)}
                      >
                        {releasesVersion?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </WrapperSelect>
                  </WrapperItemInput>

                  {arrayEnv.map((item: any, index: number) => {
                    return (
                      <WrapperItemInput key={index}>
                        <div className="wrapper_env">
                          <div className="wrapper_item">
                            {!false && (
                              <span
                                className="delete_env"
                                onClick={() => {
                                  deleteEnv(index);
                                }}
                              >
                                <CloseCircleOutlined />
                              </span>
                            )}
                            <div>
                              <span className="title_input">Key</span>
                              <input
                                className="bp3-input"
                                value={arrayEnv[index].name}
                                onChange={e => {
                                  changeEnvKey(index, e);
                                }}
                                placeholder="Key"
                                // disabled={disabled}
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="wrapper_item">
                            <span className="title_input">Value</span>
                            <div>
                              <input
                                className="bp3-input"
                                value={arrayEnv[index].value}
                                placeholder="Value"
                                onChange={e => {
                                  changeEnvValue(index, e);
                                }}
                                // disabled={disabled}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </WrapperItemInput>
                    );
                  })}
                  <WrapperItemInput>
                    <button className="button" onClick={addItemToEnv}>
                      Create New Env
                    </button>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="button"
                        onClick={createUi}
                        disabled={!validation || !validationName || clicked}
                      >
                        Create Ui
                      </button>
                    </div>
                  </WrapperItemInput>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </WrapperModal>
      ) : (
        ""
      )}
    </div>
  );
});
UiModal.displayName = "UiModal";
