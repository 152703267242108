import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { WarningTwoTone } from "@ant-design/icons";

const WrapperModal = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  position: relative;
  width: 300px;
  .visible-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .wrapper_button {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    position: relative;
    button {
      width: 45%;
      background: white;
      height: 30px;
      border: 1px solid black;
      border-radius: 4px;
      cursor: pointer;
    }

    button:hover {
      background: #eee8e8;
    }
  }
`;

export const ModalDelete = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));

  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      return !prev;
    });
  }, [isOpen, setIsOpen]);

  return (
    <Modal
      visible={isOpen}
      onCancel={toggleOverlay}
      onOk={() => {
        props.remove && props.item ? props.remove(props.item) : toggleOverlay();
      }}
    >
      <WarningTwoTone twoToneColor={"red"} style={{ fontSize: "20px" }} />
      <div>{`Sure you want to delete a ${props.name || "item"}`}</div>
    </Modal>
  );
});
ModalDelete.displayName = "ModalDelete";
