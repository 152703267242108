import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { IconName, IconNames } from "@blueprintjs/icons";
import { Button, Collapse, Icon, Overlay, Tooltip } from "@blueprintjs/core";
import { Help, ListItem, Name, Version } from "./JSDependencies";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { IframeModal } from "./PluginGroup/IframeModal";
import { ReactComponent as CopyIcon } from "../../../assets/icons/control/copy.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/control/delete.svg";
import { AppToaster } from "../../../components/editorComponents/ToastComponent";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";
import ApiDatabase from "../../../api/ApiDatabase";
import { DatabaseModal } from "./PluginGroup/DatabaseModal";
import { DatabaseShowModal } from "./PluginGroup/DatabaseShowModal";
import {
  AddNodeRed,
  Info,
  NodeRedCollapse,
  WrapperCollapse,
  WrapperItemCollapse,
} from "./NodeRedCollapse";
import { ReactComponent as Open } from "../../../assets/images/work_space/open.svg";
import { ReactComponent as Close } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as Add } from "../../../assets/images/work_space/add.svg";

export const DbCollapse = forwardRef((props: any, ref) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [itemShow, setItemShow] = useState({});
  const [isErrorModal, setIsErrorModal] = useState(false);
  const openModal = () => {
    childRef?.current?.openModal();
  };
  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));
  const datasourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );
  const filterDataSource = datasourcesList.filter((item: any) => {
    if (item.id && item.type == "db") {
      return true;
    }
  });

  const openModalShow = (item: any) => {
    setItemShow(item);
    showModalRef?.current?.openModal();
  };

  const currentApplicationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.id,
  );

  const icon = isOpen ? <Open></Open> : <Close></Close>;

  const childRef = useRef<{ openModal: any; setValue: any }>();
  const showModalRef = useRef<{ openModal: any; setValue: any }>();
  const refModalsDelete = useRef<any>({});
  const openModalDelete = useCallback(index => {
    if (refModalsDelete) {
      refModalsDelete.current[index].openModal();
    }
  }, []);

  const openDb = useCallback(() => {
    console.log();
  }, []);
  const handleCollapse = useCallback(async () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    // const { data } = await ApiDatabase.dbInfo(currentApplicationId || "");
    // setData(data);
    setIsOpen(prevState => !prevState);
  }, []);
  const handleDelete = useCallback(
    async (item: any) => {
      const { data } = await ApiDatabase.dbDelete({
        application_id: currentApplicationId,
        service_label: item.datasourceConfiguration.service_label,
        id: item.id,
      });
      dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      setIsOpen(prevState => !prevState);
    },
    [datasourcesList, currentApplicationId],
  );
  return (
    <WrapperItemCollapse>
      <WrapperCollapse className={`${isOpen && "active"}`}>
        <div
          onClick={handleCollapse}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="icon_open_close">{icon}</div>
          <span style={{ paddingLeft: "4px" }}>Data Source</span>
        </div>
        <AddNodeRed onClick={openModal}>
          {" "}
          <Add></Add>
        </AddNodeRed>
      </WrapperCollapse>
      <Collapse isOpen={isOpen}>
        {filterDataSource.map((item: any, index: number) => {
          return (
            <ListItem key={item.name}>
              <Name onClick={() => openModalShow(item)}>
                <span className="dots">{item.name}</span>
              </Name>
              <Info className="info_collapse">
                <DeleteIcon
                  width={"20px"}
                  onClick={() => {
                    openModalDelete(index);
                  }}
                ></DeleteIcon>

                <ModalDelete
                  ref={element => (refModalsDelete.current[index] = element)}
                  name={`Database  ${item.database_name}`}
                  item={item}
                  remove={handleDelete}
                />
                {/*<CopyIcon*/}
                {/*  width={"20px"}*/}
                {/*  onClick={() => {*/}
                {/*    navigator && navigator.clipboard.writeText(item.props);*/}
                {/*    AppToaster.show({ type: "success", message: "Copied Url" });*/}
                {/*  }}*/}
                {/*></CopyIcon>*/}
              </Info>
            </ListItem>
          );
        })}
      </Collapse>
      <DatabaseShowModal ref={showModalRef} item={itemShow} />
      <DatabaseModal ref={childRef} />
    </WrapperItemCollapse>
  );
});
DbCollapse.displayName = "DbCollapse";
